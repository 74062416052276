import React from "react"

import './main.less'

const Main = ({children}) => (
  <main>
    {children}
  </main>
)

export default Main