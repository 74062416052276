/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import Header from "./header"
import Footer from "./footer"
import Main from "./main"

import "./layout.less"

const Layout = ({ children, title, keywords , submenu}) => (
  <>
    <SEO title={title} keywords={keywords} />

    <Header submenu={submenu}/>

    <Main children={children}/>
    
    <Footer/>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
