import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { Location } from '@reach/router';

import './header.less'

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allMainmenuJson {
          edges {
            node {
              title
              path
            }
          }
        }
      }
    `}

    render={data =>
      <Location>
        {({ navigate, location }) =>
          <header>
            <content>
              <div id="topLogo"><span>Hazardous Frog</span></div>
              <ul className="mainMenu">
                {data && data.allMainmenuJson.edges.map(edge => {

                  // Overrides activeClassName for the link to HOME, unless HOME is the active link.
                  // partiallyActive does not work when you have / in the list since it is ALWAYS active!
                  let activeClassName = (edge.node.path === '/') ? (location.pathname === '/' ? 'selected': null) : 'selected'

                  return <li key={edge.node.path}><Link to={edge.node.path} activeClassName={activeClassName} partiallyActive={true}>{edge.node.title}</Link></li> 
                })}
              </ul>
              {props.submenu && 
                <ul className="submenu">
                  {props.submenu.map(item => (
                    <li key={item.node.path}><Link to={item.node.path} activeClassName="selected">{item.node.title}</Link></li> 
                  ))}
                </ul>
              }
            </content>
          </header>
        }
      </Location>
    }
  />
)

export default Header