import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Tooltip from "@material-ui/core/Tooltip"

import './tooltip.less'

const TooltipText = (props) => (

  <StaticQuery
    query={graphql`
      query {
        allTooltipJson {
          edges {
            node {
              code
              title
              content
            }
          }
        }
      }
    `}

    render={data => {

      let tip = data.allTooltipJson.edges.filter(edge => edge.node.code === props.children)

      if (!tip.length) return props.children

      return (
        <Tooltip title={
          <React.Fragment>
            <h4>{tip[0].node.title}</h4>
            <p>{tip[0].node.content}</p>
          </React.Fragment>
        }><span className="tooltipText">{props.children}</span></Tooltip>
      )
    }}
  />      
)

export default TooltipText