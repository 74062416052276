import React from "react"
import { Link } from 'gatsby'
import { Location } from '@reach/router';

import Button from "@material-ui/core/Button"

import './next.less'

const Feature = (props) => (
  
  <Location>
    {({ navigate, location }) => {

      let next = props.submenu.filter((item,index) => {
        return (index > 0 ? (props.submenu[index-1].node.path === location.pathname) : false)
      })

      let prev = props.submenu.filter((item,index) => {
        return (index < props.submenu.length -1 ? (props.submenu[index+1].node.path === location.pathname) : false)
      })

      return (
        <div className="links">
          {prev.length > 0 &&
            <div className="prevLink">
              <Link to={prev[0].node.path}><Button variant="outlined" color="primary" classes={{root:'myButtonLabel',outlinedPrimary:'myButtonOutline'}}>{prev[0].node.title}</Button></Link>
            </div>
          }

          {next.length > 0 &&
            <div className="nextLink">
              <Link to={next[0].node.path}><Button variant="outlined" color="primary" classes={{root:'myButtonLabel',outlinedPrimary:'myButtonOutline'}}>{next[0].node.title}</Button></Link>
            </div>
          }
        </div>
      )
    }
  }
  </Location>
)


export default Feature
