import React from "react"

import './flex-layout.less'

const Flex = (props) => {

  return (

  <div className="flex">

      {props.children.map((item,index) => (
        <span className="flexitem" key={index}>
          {item}
        </span>
      ))}

    </div>
  )
}

export default Flex
