import React from "react"
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import FlexLayout from "./flex-layout"

import Mailto from "react-protected-mailto"

import './footer.less'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        mapImage: file(relativePath: {eq: "map.png"}) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        smInImage: file(relativePath: {eq: "sm-linked-in.png"}) {
          childImageSharp {
            fixed(width: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        smTwitter: file(relativePath: {eq: "sm-twitter.png"}) {
          childImageSharp {
            fixed(width: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}

    render={data =>

      <footer>
        <content>
          <div className="spacedFooter">
            <span className={['footerItem','left'].join(' ')}>
              <p className="title">Smartix highlights</p>
              <p><Link to="/smartix/reservations/#advanced-selection">Advanced Seat Selection</Link></p>
              <p><Link to="/smartix/split-ticketing/">Enhanced Split Ticketing Support</Link></p>
              <p><Link to="/smartix/reservations/#seat-maps">Interactive seats maps</Link></p>
              <p><Link to="/smartix/architecture/#multi-version">Multi-version</Link></p>
              <p><Link to="/smartix/architecture/#error-tracking">Error tracking system</Link></p>
            </span>

            <span className={['footerItem','middle'].join(' ')}>
              <p className="title">Email</p>
              <Mailto
                email="admin@HazardousFrog.com"
                headers={{ subject: "Customer enquiry" }}
              />

              <p className="title">Social Media</p>
              <a href="https://www.linkedin.com/company/hazardous-frog/about/"><Img fixed={data.smInImage.childImageSharp.fixed} alt="linked in" className="sm"/></a>
              <a href="https://twitter.com/hazfrog"><Img fixed={data.smTwitter.childImageSharp.fixed} alt="twitter"  className="sm"/></a>
            </span>

            <span className={['footerItem','right'].join(' ')}>

              <FlexLayout>
                <div style={{whiteSpace:"nowrap"}}>
                  <p className="title">Address</p>
                  Hazardous Frog<br/>
                  Newfoundland Way<br/>
                  Portishead<br/>
                  Bristol<br/>
                  BS20 7PT

                  <p>Company number : 03811078</p>
                </div>

                <div>
                  <p className="title">Map</p>
                  <a href="https://goo.gl/maps/5WS6d5CJCGGnohPU8" target="_blank" rel="noopener nofollow noreferrer">
                    <Img fixed={data.mapImage.childImageSharp.fixed} alt="map"/>
                  </a>
                </div>
              </FlexLayout>
            </span>
          </div>

        </content>

      </footer>
    }
  />
)

export default Footer